body {
    overflow-x: hidden;
}
.App{
    // margin-top: 110px;
}
.MainHeader{
    // position: fixed;
    // width: 100%;
    // z-index: 999;
    // top: 0;
}
.pageHeader{
    padding: 14vh 0;
    background-position: center;
    background-size: cover;
    color: #fff;
}

.engineContainer{
    background: #fff;
    padding: 12vh 0;

    h3{
        color: #000;
        font-weight: bold;
    }

    .col-md-8{
        padding-top: 15px;
        img{
            margin-right: 10px;
        }
    }
}

.FaqComponent{
    .contentBox{
        .imageWrap{
            display: inline-block;
            margin-bottom: 15px;
            position: relative;

            img {
                width: 100%;
            }
        
             > p {
                &.imageText {
                    position: absolute;
                    margin: 0;
                    width: 100%;
                    padding: 1vw;
                    text-transform: uppercase;
                    color: #fff;
                    background: rgba(0,0,0,0.7);
                    font-size: 1.5rem;
                    font-weight: bold;
                }
            }
    
        }
    
        button{
            background: transparent !important;
            color: #000 !important;
    
            .expandIcon{
                float: right;
                margin: 1px;
                background: #eaeaea;
                width: 30px;
                height: 30px;
                padding: 8px;
                border-radius: 50%;
                transition: all 0.2s ease-in-out;
    
                &:hover{
                    background: #ccc;
                }
            }
    
        }
    }

    table{
        width: 95% !important;

        tr{
            td, *{
                font-family: inherit !important;
            }
            p{
                margin: 10px 0;
            }
        }
    }

    .conferencesList{
        .row{
            // border-top: 1px #eaeaea solid;
            padding: 10px 0;
            font-size: 14px;

            &.listHeading{
                font-size: 16px;
                color: #003584;
            }

            &:nth-child(even){
                background: #effaff;
            }
        }
    }

    .galleryBox{
        .col-md-3{
            border: 1px #fff solid;
            background: #eaeaea;
        }
        img{
            max-height: 150px;
        }
    }

    .ourClients{
        padding: 0;
        
        .row {
            padding-bottom: 30px;
        }
        h2{
            &.categoryTitle{
                font-size: 18px;
                border-bottom: 1px #ccc solid;
                padding: 10px;
            }
        }
        .clientBox {
            font-size: 14px;
            // margin: 15px 0;

            p{
                margin: 0;

                &:first-child {
                    color: #0956c7;
                    font-weight: 500;
                }
            }
            .col-xs-6{
                width: 50%;
                float: left;

                img{
                    border: 1px #eaeaea solid;
                }
            }
        }
    }

}

.partnerLogos {
    .container{
        padding: 8vh 0;
    }
    .logoBox {
        width: 240px;
        min-height: 140px;
        display: inline-block;
        vertical-align: middle;
        background: #eaeaea;
        margin: 20px auto;
        box-shadow: 0px 20px 40px -20px #333;
        position: relative;
        bottom: 0;
        transition: all 0.2s ease-in-out;

        &:hover{
            bottom: 5px;
            box-shadow: 0px 30px 40px -20px #333;
        }
    }
}

.reportSlider2{

    .slick-slider{
        max-width: 90%;
        margin: auto;

        .slick-slide{
            img{
                box-shadow: 0px 0px 19px -10px #333;
                margin: 10px auto !important;
            }
        }
    }

    .slick-prev,
    .slick-next{
        &::before{
            color: #333;

            @media screen and (min-width: '600px'){
                font-size: 30px;
            }
        }
    }

    .slick-dots{
        bottom: inherit;

        @media screen and (max-width: '600px'){
            display: none !important;
        }
    }
}

.adoptCityButtonTop{
    margin: 0 20px;
    background: #f4d506;
    transition: all 0.2s ease-in-out;

    &:hover{
        background: #dabe00;
    }
    
    a {
        text-align: center;
        text-transform: none;
        font-weight: bold;
        color: #000 !important;
    }
}

.selectRegionsModal {
    .modal-content {
        background-color: #061935;
        color: #fff;

        .modal-header {
            .close {
                text-shadow: none;
                color: #fff;
            }
        }
    }
    .col-md-4 {
        padding: 0;
    }
    .btn {
        &.btn-link {
            margin-bottom: 10px;
            padding: 10px;

            &:hover{
                background-color: #fff;
                text-decoration: none !important;
                
                img {
                    transform: scale(1);
                }
                p {
                    font-weight: bold;
                    color: #000;
                }
            }

            img{
                max-width: 140px;
                transform: scale(0.9);
                transition: all 0.2s ease-in-out;
            }

            p {
                margin: 0;
                color: #fff;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}

.playPressBtn {
    float: left;
    display: block;
    margin: 5vh 0;
    width: 100%;
    
    img {
        float: left;
        max-width: 20%;
    }
    p{
        float: left;
        max-width: 80%;
        text-align: left;
        font-family: inherit;
        color: #fff;
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding-left: 20px;
        padding-top: 60px;
    }
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ffc800;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  

  .pro-sidebar {
      background: #444444 !important;

      &.collapsed {
        background: #eaeaea !important;

        .pro-menu.shaped {
            .pro-menu-item {
                > .pro-inner-item {
                    > .pro-icon-wrapper {
                        background: transparent;
                    }
                }
            }
        }

        
        .pro-menu {
            .pro-menu-item {
                > .pro-inner-item {
                    > .pro-icon-wrapper {
                        .pro-icon {
                            font-size: 22px;
                            color: #929292;
                        }
                    }
                }
            }
        }

      }

      &:not(.collapsed) {
        .pro-menu {
            .pro-menu-item {
                > .pro-inner-item {
                    padding: 8px 5px 8px 10px;

                    .pro-icon-wrapper {
                        width: 25px;
                        min-width: 25px;
                        height: 25px;
                        line-height: 25px;
                        background: #606060 !important;

                        .pro-icon {
                            font-size: 12px !important;
                        }

                    }

                }
                
                &.subItem {
                    padding-left: 20px;
                    font-size: 14px;
                    
                    > .pro-inner-item {
                        padding-top: 0;
                        
                        .pro-icon-wrapper {
                            background: transparent !important;
                            .pro-icon {
                                font-size: 10px !important;
                            }
                        }
                    }
                }
            }
        }

      }

       > .pro-sidebar-inner {
            .pro-sidebar-header {
                background: #606060;
                color: #fff;
                height: 56px !important;
                line-height: 56px !important;

                .userIconHeader {
                    font-size: 22px;
                    margin: -5px 5px;
                }
            }
            .pro-sidebar-content {
                padding-top: 18px;
            }
        }

  }

  .table {
      &.table-striped {
        thead {
            th {
                font-weight: 500;
                text-transform: uppercase;
            }
        }
      }
  }

  .controlsManageContainer {
    .input-group {
        margin: 15px 0;
        .input-group-text{
            border-radius: 0;
            background: #fff;
            padding: 10px 15px;
            border: 2px #ccc solid;
            max-width: 90%;
            margin: auto;
            position: relative;

            &.nested {
                width: 80%;

                span {
                    margin-left: 60px !important;
                }
            }

            span {
                color: #606060;
                font-size: 18px;
                margin-top: 25px;
                margin-left: 30px !important;

                &.assignLabel{
                    position: absolute;
                    top: 15px;
                    left: 75px;
                    margin-left: 0 !important;
                    margin-top: 0;
                    font-size: 14px;
                }

            }

            &::before {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: #ccc;
                border-radius: 50%;
                left: 25px;
                background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/768px-White_check.svg.png');
                background-repeat: no-repeat;
                background-size: 60%;
                background-position: center;
            }

            input[type=checkbox] {
                width: 20px;
                height: 20px;
                opacity: 0;
                cursor: pointer;
                margin-left: 10px;
            }

            &.selected {
                border: 2px #92d36e solid;
                span {
                    color: #72bb53;
                }

                &::before {
                    background-color: #72bb53;
                }
            }
        }
    }
  }

  .AssessmentStatistics {
      margin-top: 20px;
      padding-bottom: 10vh;
      background-color: #f6fbff;


      &.EmpReports {
        .form-group {
            select {
                // background: transparent;
                border: none;
            }
        }
      }

      &.ManageAssessment {
        .form-group {
            // select {
            //     // background: transparent;
            //     border: none;
            // }
        }
        .row {
            .card {
                .form-group {
                    margin-bottom: 0px;
                }
            }
        }
      }

      .form-group {
          margin-top: 15px;
          text-align: left;
          
          label {
              font-size: 12px;
              margin: 0;
          }

          select {
            font-size: 12px;
            height: 30px;
            padding: 0px 10px;
          }
      }

    //   &::before {
    //       content: '';
    //     //   background-color: #c9f1fd;
    //       position: fixed;
    //       z-index: -1;
    //       width: 100vw;
    //       height: 100vh;
    //       left: 0;
    //       top: 0;
    //   }

    .breadcrumbs {
        font-size: 12px;
        color: #999;
    }

    .toggleIconHere {
        position: absolute;
        top: 3px;
        left: 15px;
        color: rgb(0, 0, 0);
        cursor: pointer;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        border: 1px #666 solid;
        padding: 5px;
    }

    h5 {
        padding: 0 35px;
    }

    .row {
        .card {
            padding: 0 15px;
            margin-bottom: 20px;
            border: 1px #eaeaea solid !important;

            .row {
                border-bottom : 1px #eaeaea solid;
                padding: 10px 0;

                .progress {
                    width: 80%;
                    float: right;
                    height: 13px;
                    margin: 7px 0 0 0;
                    background-color: transparent;
                    border-radius: 0px;

                    .progress-bar {
                        border-radius: 0px;
                    }

                    &.progressBar1 {
                        .progress-bar {
                            background-color: #75a9f9 !important;
                        }
                    }
                    &.progressBar2 {
                        .progress-bar {
                            background-color: #0c59cf !important;
                        }
                    }
                }
                
                p {
                    margin: 0;

                    &.inlineB {
                        display: inline-block;
                        font-size: 12px;
                    }
                }
            }
        }
    }

  }


.AssessmentHistory {
    .topButtons {
        // padding-bottom: 5vh;
        background: #f6fbff;
        
        .col {
            padding: 0;
        }
        button {
            background: #eaeaea;
            font-weight: 500;
            box-shadow: none !important;
            outline:none !important;
            border-radius: 0;
            border-bottom: 1px transparent solid;
            color: #000;
            position: relative;
            bottom: -1px;
            font-size: 15px;
            text-transform: uppercase;
            padding: 12px 25px;
            border-color: #dee2e6 #dee2e6 #fff;

            &.active {
                background: #f6fbff !important;
                border-color: #dee2e6 #dee2e6 #fff !important;
                color: #000 !important;
            }
        }
    }
    .bgColorApplied {
        overflow: hidden;
        position: relative;
        border-bottom: 1px #eaeaea solid;
        border-left: 1px #eaeaea solid;
        border-right: 1px #eaeaea solid;
        padding: 10px 0;
        font-size: 13px;
        text-align: left;

        .form-group {
            margin-bottom: 0;
            select {
                font-size: 13px;
                position: relative;
                top: -5px;
                font-weight: bold;
                background: transparent;
                border: 0;
                outline: none;
                box-shadow: none;
                padding-left: 0;
                max-width: 140px;
            }
        }

        &::before{
            content: '';
            width: 100vw;
            height: 200%;
            left: 0;
            top: 0;
            position: absolute;
            background: #f6fbff;
        }

    }
}