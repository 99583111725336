.ProfileComponentEmployee {
    background: #f6fbff;

    .container-fluid {
        padding: 0;
    }
    
    .nav-tabs {
        text-align: center;
        background-color: #fff;
        display: block;

        .nav-item {
            // margin: auto;
            // width: 25%;
            cursor: pointer;
            display: inline-block;
            width: 256px;

            .nav-link {
                color: #000;
                position: relative;
                bottom: -1px;
                padding: 12px 25px;

                &.disabled {
                    opacity: 0.5;
                }
                
                &.active {
                    font-weight: 500;
                    background: #f6fbff;
                    border-bottom: 1px transparent solid;
                }
            }
        }
    }

    .form-group {
        padding: 10px;
        background: #fff;
        border: 1px #eaeaea solid;
        border-radius: 5px;
        position: relative;

        &.highlight {
            border: 1px red solid;

            label {
                color: red;
            }

            .updateIcon {
                color: red;
            }

            .form-control {
                border-top: 1px #ffe0e0 solid;
                color: red;
            }
        }

        &.changed {
            border: 1px #004085 solid;
            position: relative;

            &::before {
                content: 'update';
                position: absolute;
                right: 5px;
                top: 7px;
                font-size: 11px;
                color: #004085;
                // background: #004085;
                padding: 4px 25px 5px 10px;
                line-height: 12px;
                border-radius: 10px;
            }

            label {
                color: #004085;
            }

            .updateIcon {
                color: #004085 !important;
                display: block;
            }

            .form-control {
                // border-top: 1px #ccc solid;
                color: #004085;
            }
        }

        label {
            font-size: 12px;
            padding-left: 5px;
            color: #999;
            margin: 0;
        }

        .form-control {
            background: #fafafa;
            padding-left: 5px;
            border: none;
            border-radius: 0;
            // border-top: 1px #f5f5f5 solid;
        }

        .updateIcon {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            color: #ccc;
            display: none;

            &:hover {
                color: #666;
            }
        }
    }

    .boxFieldForm {
        padding: 0;
        border: none;
        input {
            display: inline-block;
            width: 40px;
            margin: 0 5px;
            text-align: center;
            padding-left: 0;
            height: 40px;
            padding-right: 0;
            background: transparent !important;
            border: 1px #ccc solid !important;

            @media screen and (max-width: '700px') {
                width: 40px;
                border-radius: 0;
                margin: -1px;
            }
        }
    }
    
}