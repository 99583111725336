
.TalentMapStatsComponent{
    margin-top: -94px;
    min-height: 100vh;
    background: #020a15;
    color: #000;

    h1, h2, h3, h4, h5, h6 {
        color: #fff;
    }

    svg {
        color: #000 !important;
        overflow: visible;
        text{
            fill: #fff !important;
            font-family: inherit !important;

            font-size: 15px !important;
        }
        
        g[transform="translate(0,360)"],
        g[transform="translate(0,120)"]
        {
            display: none;
        }
    }

    .radio{
        display: inline-block !important;

        label{
            background: #333;
            // border: 1px #ccc solid;
            color: #fff;
            font-size: 0.8rem;
            padding: 3px 15px 8px 10px;
            border-radius: 30px;
            margin: 0 5px;
            cursor: pointer;

            input{
                width: 20px;
                height: 20px;
                position: relative;
                top: 5px;
                margin-right: 8px;
            }
        }
    }

    .react-switch{
        position: relative;
        top: 2px;
        right: 5px;

        .react-switch-handle{
            width: 20px !important;
            height: 20px !important;
            top: 4px !important;
        }
        &.lowest{
            .react-switch-bg{
                background: #020a15 !important;
            }
            .react-switch-handle{
                transform: translateX(5px) !important;
                background: rgb(214, 51, 51) !important;
            }
            .switchLabel{
                text-align: right !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 4px 10px 0 32px;
            }
        }
        &.highest{
            .react-switch-handle{
                transform: translateX(66px) !important;
            }
            .switchLabel{
                text-align: left !important;
            }
        }

        .switchLabel{
            display: inline-block;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 12px;
            color: #fff;
            padding: 4px 17px;
            width: 90px;
        }

        .react-switch-bg{
            width: 90px !important;
    
            > div{
                width: 90px !important;
            }
        }
        
        &.big{
            .switchLabel{
                width: 174px;
            }
            .react-switch-bg{
                width: 174px !important;
        
                > div{
                    width: 174px !important;
                }
            }
            &.lowest{
                .react-switch-handle{
                    transform: translateX(5px) !important;
                    background: #e4ff00 !important;
                }
            }
            &.highest{
                .react-switch-handle{
                    transform: translateX(150px) !important;
                }
                .react-switch-bg{
                    background: #004aaf !important;
                }
            }
        }
        &.medium{
            .switchLabel{
                width: 120px;
            }
            .react-switch-bg{
                width: 120px !important;
        
                > div{
                    width: 120px !important;
                }
            }
            &.lowest{
                .react-switch-handle{
                    transform: translateX(5px) !important;
                    background: #e4ff00 !important;
                }
            }
            &.highest{
                .react-switch-handle{
                    transform: translateX(96px) !important;
                }
                .react-switch-bg{
                    background: #004aaf !important;
                }
            }
        }
    }

    .chartBox{
        background: #0d1623;
        border-radius: 5px;
        // box-shadow: 0px 10px 40px -16px #333;
        margin-bottom: 3vh;
        overflow: hidden;
        
        .titleHead{
            padding: 10px;
            // border-bottom: 1px #232323 solid;
            background: #10223c;

            h5{
                font-size: 16px;
                font-weight: normal;
                text-align: left;
                padding-left: 15px;
                padding-top: 5px;
            }
        }
    }

    .schoolBox{
        background: #0b2548;
        padding: 15px 30px;
        border: 1px #0544a2 solid;
        position: relative;
        min-height: 430px;
        // max-height: 430px;

        &:nth-child(2){
            background: #ea5a2c;
        }
        &:nth-child(3){
            background: #33a02c;
        }
        
        h4{
            font-size: 20px;
        }
        svg{
            margin-bottom: 20px;
            path{
                fill: #fff;
            }
        }
        .topLabel{
            font-size: 10px;
            color: #000;
            background: #1a65d6;
            padding: 4px 8px;
            // border-radius: 5px;
            position: absolute;
            right: 8px;
            bottom: 8px;
        }

        .col:nth-child(2){
            padding-right: 0;
            p{
                // background: rgb(5, 68, 162);
                line-height: 20px;
                padding: 10px;
                width: 100%;
                display: block;
            }
        }

        .circleBox {
            position: relative;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            background: #05162d;
                
            h2{
                color: #3d506b;
                font-size: 8rem;
                font-weight: bold;
                line-height: 230px;
                text-align: center;
            }
            p{
                position: absolute;
                bottom: 40px;
                font-size: 24px;
                line-height: 30px;
                color: #3d506b;
                width: 100%;
                left: 0;
                text-align: center;
            }
        }

        .circleBox2{
            position: relative;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            background: #05162d;
                
            h2{
                color: #8e2828;
                font-size: 8rem;
                font-weight: bold;
                line-height: 230px;
                text-align: center;
            }
            p{
                position: absolute;
                bottom: 40px;
                font-size: 24px;
                line-height: 30px;
                color: #8e2828;
                width: 100%;
                left: 0;
                text-align: center;
            }
            .smallCircle{
                position: relative;
                width: 120px;
                height: 120px;
                line-height: 90px;
                border-radius: 50%;
                background: #d63333;
                position: absolute;
                right: -20px;
                top: -30px;
                color: #fff;
                font-size: 3rem;
                padding-left: 12px;

                span {
                    font-size: 1.2rem;
                }

                p {
                    font-size: 14px;
                    color: #fff;
                    bottom: 0px;
                    line-height: 14px;
                }
            }

            &.extend {
                width: 260px;
                height: 260px;
                background: #0b2548;
                border: 1px #004aaf solid;
                margin: auto;
                line-height: 340px;

                .iconHere {
                    margin: 0;

                    path {
                        fill: #3a5b8a;
                    }
                }

                .smallCircle{
                    width: 100px;
                    height: 100px;
                    line-height: 70px;
                    left: 8px;
                    bottom: -10px;
                    top: inherit;
                    font-size: 2.6rem;
    
                    p {
                        font-size: 10px;
                        bottom: 0px;
                        line-height: 12px;
                    }
                }

                // &.extend2 {
                //     .smallCircle{
                //         width: 100px;
                //         height: 100px;
                //         line-height: 70px;
                //         right: -20px;
                //         bottom: -30px;
                //         left: inherit;
                //         top: inherit;
                //         font-size: 2.6rem;
                        
                //     }
                // }

            }
        }

        .skillHere{
            font-size: 16px;
            line-height: 24px;
            display: block;
            position: relative;
            cursor: pointer;
            transition: background 0.2s ease-in-out;

            &.highlighted {
                background: #d63333;
                padding: 5px 10px 8px 5px;
                left: -30px;
                padding-left: 30px;
                font-size: 20px;
                // line-height: 20px;
                width: calc(100% + 40px);
                border-radius: 2px;
                
                &:hover {
                    &::before{
                        background: transparent;
                    }
                }
            }

            &::before{
                content: '';
                width: 12px;
                height: 12px;
                left: -20px;
                top: 7px;
                position: absolute;
                background: transparent;
                border-radius: 50%;
                // transition: all ease-in-out 0.1s;
            }


            &:hover {
                &::before{
                    background: #d63333;
                }
            }


            &.small{
                font-size: 14px;
                border-bottom: 1px #164584 solid;
                padding: 5px 20px 5px 0px;
                position: relative;
                line-height: 18px;

                &::before{
                    background: transparent;
                }

                .dot {
                    width: 10px;
                    height: 10px;
                    line-height: 5px;
                    position: absolute;
                    border-radius: 50%;
                    right: -15px;
                    top: calc(50% - 5px);

                    .dotText { 
                        opacity: 0;
                        font-size: 14px;
                    }
                }

                &:hover{
                    .dot {
                        color: inherit;
                        background: transparent !important;
                        right: -1px;

                        .dotText { 
                            opacity: 1;
                        }
                    }
                }
            }

            .skillSpinner{
                position: absolute;
                top: 10px;
                left: 7px;
                border-width: 0.15rem;
            }
        }

        .bottomLabel{
            position: absolute;
            left: 0;
            bottom: 12px;

            p {
                margin: 0;
                text-align: left;
                line-height: 16px;
                font-size: 12px;
                color: #435f84;
            }
        }

        .situationsParent{
            color: #fff;
            max-height: 398px;
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 15px;
            position: absolute;
            width: calc(100% - 15px);
            top: 50%;
            transform: translateY(-50%);

            &::-webkit-scrollbar {
                width: 0.3em;
            }
             
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px #05162d;
            }
             
            &::-webkit-scrollbar-thumb {
                background-color: #006cff;
                outline: 1px solid #006cff;
                border-radius: 7px;
                cursor: pointer;
            }

        }

        &.topSchoolBox {
            min-height: 307px;
            position: relative;
            background: #1b293e;
            border: 1px #495971 solid;

            // > .row {
            //     background-size: cover;
            // }

            .oneInfo {
                display: inline-block;
                margin: 10px 20px 0 20px;
                color: #fff;

                .iconBox {
                    width: 140px;
                    height: 140px;
                    background: #081d3c;
                    border: 5px #495971 solid;
                    line-height: 140px;
                    padding: 28px 0;
                    border-radius: 50%;
                    margin-bottom: 10px;
                }
                svg {
                    font-size: 55px;
                    path {
                        fill: #495971;
                    }
                }
                p {
                    margin: 0;
                }
            }

            .circleBox {
                width: 200px;
                height: 200px;
                // padding: 5px;
                border: 10px #495971 solid;
                margin: auto;
                overflow: hidden;
                
                .cityLogo {
                    // max-width: 100%;
                    width: 100%;
                }

                &.imageBack {
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

            }
            
            .cityName {
                margin: 1vh 0;
                font-size: 1.5rem;
            }

            .titleTag {
                position: absolute;
                left: 10px;
                top: 10px;
                background: #006cff94;
                color: #fff;
                padding: 3px 15px;
                font-size: 12px;
                border-radius: 20px;
            }

            
        }

    }


    .customSpinnerChart {
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: rgba(13,22,35,0.8);
        z-index: 999;

        > div {
            position: relative;
            top: calc(50% - 2rem);
        }
    }


    .integratedFormSideBar{
        position: fixed;
        // background: #0d1623;
        background: #000;
        height: 100vh;
        width: 45vw;
        z-index: 999;
        top: 0;
        right: -45vw;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;

        &.active{
            right: 0;
            box-shadow: 6px 0px 100px 30px #000;
            -webkit-box-shadow: 6px 0px 100px 30px #000;
            -moz-box-shadow: 6px 0px 100px 30px #000;

            .citiesListBarSwitch {
                right: calc(100% - 15px);
            }
        }
        
        .citiesListBarSwitch {
            position: absolute;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 14px;
            border-radius: 50%;
            bottom: 50px;
            right: 0;
            background: #d41b5a;
            box-shadow: -9px 8px 34px 2px #000;
            border: none;
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active {
                background: #d41b5a;
                box-shadow: none;

                &.green {
                    background: #b7f301;
                    box-shadow: none;
                }

                &.yellow {
                    background: #e4ff00;
                    box-shadow: none;
                }

            }

            &.opened {
                width: 40px;
                height: 40px;
                background: #d41b5a;
                border-radius: 50%;

                &.green {
                    background: #b7f301;

                    svg {
                        color: #000 !important;
                    }
                }

                &.yellow {
                    background: #e4ff00;

                    svg {
                        color: #000 !important;
                    }
                }

                span {
                    opacity: 0;
                }
            }

            span {
                font-size: 12px;
                opacity: 1;
            }

            svg {
                color: #fff !important;
            }
        }

        & > iframe {
            position: relative;
            width: 100%;
            height: 100%;
            border: none;

            &::-webkit-scrollbar {
                width: 0.4em;
            }
            
            &::-webkit-scrollbar-track {
                // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #445265;
                outline: 1px solid #445265;
                border-radius: 10px;
            }
        }
    }

    .citiesListBarContainer{
        position: fixed;
        // background: #0d1623;
        background: #2c3542;
        height: 100vh;
        width: 24vw;
        z-index: 999;
        top: 0;
        left: -24vw;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;

        .citiesListBarSwitch {
            position: absolute;
            width: 70px;
            height: auto;
            text-align: center;
            line-height: 14px;
            border-radius: 0;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            bottom: 50px;
            left: calc(100% + 0px);
            background: #d41b5a;
            box-shadow: -9px 8px 34px 2px #000;
            border: none;
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            
            &:not(:disabled):not(.disabled).active,
            &:not(:disabled):not(.disabled):active {
                background: #d41b5a;
                box-shadow: none;
            }

            &:hover {
                width: 90px;
            }

            &.opened {
                width: 40px;
                height: 40px;
                background: #d41b5a;
                border-radius: 50%;

                span {
                    opacity: 0;
                }
            }

            span {
                font-size: 12px;
                opacity: 1;
            }

            svg {
                color: #fff !important;
            }

        }

        &.active{
            left: 0;
            box-shadow: 6px 0px 100px 30px #000;
            -webkit-box-shadow: 6px 0px 100px 30px #000;
            -moz-box-shadow: 6px 0px 100px 30px #000;

            // &::after{
            //     content: '';
            //     position: fixed;
            //     width: 100%;
            //     height: 100%;
            //     left: 0;
            //     top: 0;
            //     background: rgba(0,0,0,0.5);
            //     z-index: -1;
            // }

            .citiesListBarSwitch {
                left: calc(100% - 15px);
            }
        }



        .cityHere{
            font-size: 16px;
            line-height: 24px;
            display: block;
            position: relative;
            cursor: pointer;
            padding-left: 30px;
            transition: background 0.2s ease-in-out;

            &.highlighted {
                background: #d41b5a;
                padding: 5px 10px 8px 5px;
                left: 0;
                padding-left: 30px;
                font-size: 20px;
                // line-height: 20px;
                width: calc(100%);
                border-radius: 2px;
                
                &:hover {
                    &::before{
                        background: transparent;
                    }
                }
            }

            &::before{
                content: '';
                width: 12px;
                height: 12px;
                left: 8px;
                top: 6px;
                position: absolute;
                background: transparent;
                border-radius: 50%;
                // transition: all ease-in-out 0.1s;
            }


            &:hover {
                &::before{
                    background: #d41b5a;
                }
            }


            &.small{
                font-size: 14px;
                border-bottom: 1px #164584 solid;
                padding: 5px 20px 5px 0px;
                position: relative;
                line-height: 18px;

                &::before{
                    background: transparent;
                }

                .dot {
                    width: 10px;
                    height: 10px;
                    line-height: 5px;
                    position: absolute;
                    border-radius: 50%;
                    right: -15px;
                    top: calc(50% - 5px);

                    .dotText { 
                        opacity: 0;
                        font-size: 14px;
                    }
                }

                &:hover{
                    .dot {
                        color: inherit;
                        background: transparent !important;
                        right: -1px;

                        .dotText { 
                            opacity: 1;
                        }
                    }
                }
            }

            .citySpinner{
                position: absolute;
                top: 10px;
                left: 7px;
                border-width: 0.15rem;
            }
        }

        .customSpinnerChart {
            height: calc(100% - 50px);
            text-align: center;
            background: transparent;
        }

        .mainCitiesBox {
            overflow-y: auto;
            height: calc(100vh - 120px);
            padding-right: 12px;
            
            &::-webkit-scrollbar {
                width: 0.4em;
            }
            
            &::-webkit-scrollbar-track {
                // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: #445265;
                outline: 1px solid #445265;
                border-radius: 10px;
            }
        }

        input[name=citySearchField] {
            background: #445265;
            border-radius: 0;
            border: none;
            color: #fff;
            font-size: 14px;

            &::placeholder {
                color: #ccc;
            }
        }

    }


    .quickIcons{
        display: inline-block;
        position: absolute;
        top: 3px;
        right: 145px;
        // background: #004aaf;

        .customIcon {
            margin: 0px 5px;
            color: #fff;
            width: 26px;
            height: 26px;
            background: #081d3c;
            padding: 6px;
            border: 1px #3a5b8a solid;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.1s ease-in-out;

            path {
                fill: #3a5b8a;
            }

            &:hover {
                // background: #020a15;
                border: 1px #fff solid;
                path {
                    fill: #fff;
                }
            }
        }
    }

}

.tableForData{
    &.table {
        color: #fff;
        max-width: 80%;
        margin: 40px;
    }
}

.customModal{
    color: #000;

    button{

        background: #d41b5a;
        border: none;

        &:not(.close){
            &:hover{
                background: #9a0a3b;
            }
        }
        

        &.close {
            background: #d41b5a;
            opacity: 1;
            color: #fff;
            text-shadow: none;
            // border-top-right-radius: 6px;
        }
    }
}

.swarmDataChartBox{
    position: relative;
    height: 2700px;

    > .allcities,
    > .specificcity {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    > .allcities {
        opacity: 0;
    }

    > .specificcity {
        opacity: 1;
    }

    &.active {
        > .allcities {
            opacity: 1;
            z-index: 999;

            svg {
                circle {
                    opacity: 0.4;
                }
            }

        }
    
        > .specificcity {
            opacity: 1;
            z-index: 0;
        }
    }

}

.contactForCity {
    position: absolute;
    right: 10px;
    bottom: -5px;
    width: 270px;
    text-align: right;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #85aee4 !important;
        
        span{
            color: yellow !important;   
        }
    }
}

// .modal-backdrop{
//     background: #0056b3;
// }