// .App{
//     font-family: 'Open Sans', sans-serif;
//   }

.bannerImage{
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #034d9c;

  // & * {
  //   z-index: 2;
  // }
  
  &::before{
    content: '';
    background: rgba(0,0,0,0);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.MainFormComponent{
  &.blue{
    
    .QuestionComponent {
      padding: 1.5vh 0.5vw 1.5vh 1vw;
      margin: 15px 0 2vh 0;
      // background: rgba(0, 123, 255, 0.04);
      border: 1px rgba(0, 123, 255, 0.4) solid;
      position: relative;
      border-radius: 5px;
      font-size: 1rem;

      &.errorRequired {
        // border-bottom: 1px red solid;
        border: 1px red solid;
        .index {
          background: red;
          color: #fff;

          &::after{
            content: 'Required';
            position: absolute;
            top: 0px;
            color: red;
            left: -60px;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    
      .QuestionComponent {
        margin-left: 0px;
    
        & ~ .QuestionComponent {
          
          // margin-top: -2vh;
          // border-top: 0;
          margin-bottom: 1vh;
          
          &::before{
            border: none;
          }
        }
    
        &:after, &:before {
          bottom: calc(100% + 1px);
          left: calc(0% + 8px);
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        
        &:before {
          border-color: rgba(194, 225, 245, 0);
          border-bottom-color: rgba(0, 43, 255, 0.6);
          border-width: 5px;
          margin-left: -5px;
        }

      }
    
      .index {
        // position: absolute;
        // // width: 36px;
        // // height: 36px;
        // width: 10px;
        // height: 10px;
        // line-height: 36px;
        // // top: calc(50% - 18px);
        // bottom: 30px;
        // left: -18px;
        // // background: #edf6ff;
        // // color: #034d9c;
        // background: transparent;
        // color: transparent;
        // font-size: 16px;
        // text-align: center;
        // border-radius: 50%;


        position: absolute;
        width: 10px;
        height: 10px;
        line-height: 36px;
        bottom: 10px;
        right: 10px;
        background: transparent;
        color: transparent;
        font-size: 16px;
        text-align: center;
        border-radius: 50%;
      }
    }
  }

  &.green{
    
    .QuestionComponent {
      padding: 1.5vh 0.5vw 1.5vh 1vw;
      margin: 1.5vh 0 2vh 0;
      background: rgba(0, 255, 31, 0.04);
      border: 1px rgba(40, 113, 59, 0.4) solid;
      position: relative;
      border-radius: 5px;
    
      .QuestionComponent {
        margin-left: 0px;
    
        & ~ .QuestionComponent {
          
          // margin-top: -2vh;
          // border-top: 0;
          margin-bottom: 1vh;
          
          &::before{
            border: none;
          }
        }
    
        &:after, &:before {
          bottom: calc(100% + 1px);
          left: calc(0% + 8px);
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        
        &:before {
          border-color: rgba(194, 225, 245, 0);
          border-bottom-color: rgba(40, 113, 59, 0.7);
          border-width: 5px;
          margin-left: -5px;
        }
      }

      .custom-control-input{
        &:checked{
          ~.custom-control-label{
            &::before{
              border-color: #026f11;
              background-color: #026f11;
            }
          }
        }
      }

    }
    
  }

  &.yellow{
    
    .QuestionComponent {
      padding: 1.5vh 0.5vw 1.5vh 1vw;
      margin: 1.5vh 0 2vh 0;
      background: rgba(255, 224, 0, 0.04);
      border: 1px rgba(138, 146, 22, 0.4) solid;
      position: relative;
      border-radius: 5px;
    
      .QuestionComponent {
        margin-left: 0px;
    
        & ~ .QuestionComponent {
          
          margin-top: -2vh;
          border-top: 0;
          margin-bottom: 1vh;
          
          &::before{
            border: none;
          }
        }
    
        &:after, &:before {
          bottom: calc(100% + 1px);
          left: calc(0% + 8px);
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        
        &:before {
          border-color: rgba(194, 225, 245, 0);
          border-bottom-color: rgba(138, 146, 22, 0.7);
          border-width: 5px;
          margin-left: -5px;
        }
      }

      .custom-control-input{
        &:checked{
          ~.custom-control-label{
            &::before{
              border-color: rgba(125, 134, 0, 0.82);
              background-color: rgba(125, 134, 0, 0.82);
            }
          }
        }
      }

    }
    
  }

  &.Dark{
    background: #000;
    color: #fff;
    min-height: 100vh;


    .OneLineComponent{
        input{
            color: #fff;
            border-bottom: 1px #fff solid;
            outline: none;

            &:focus{
              background: transparent;
              outline: none;
            }
        }
    }
    
    .DateFieldComponent,
    .TimeFieldComponent{
        input{
            color: #fff;
            border-bottom: 1px #fff solid;
          
            &:focus{
              background: transparent;
              outline: none;
            }
        }
    }
    
    .ParagraphComponent {
        textarea {
            color: #fff;
            border-bottom: 1px #fff solid;

            
            &:focus{
              background: transparent;
              outline: none;
            }
        }
    }

    &.DarkForTalentmap1 {
      background: #353535;
      
      ::before {
        background: transparent;
      }
      
      .bannerImage {
        background: rgb(228, 255, 0) !important;
        color: #0a2344 !important;
      }

      .btn-warning {
        background: rgb(228, 255, 0) !important;
        border: none;
      }

    }
    
    &.DarkForTalentmap2 {
      background: #353535;
      
      ::before {
        background: transparent;
      }
      
      .bannerImage {
        background: #b7f301 !important;
        color: #0a2344 !important;
      }

      .btn-warning {
        background: #b7f301 !important;
        border: none;
      }

    }

  }



  
}


.react-confirm-alert-body {
  > h1 {
    font-size: 26px;
  }
}

@keyframes y2019-expand-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}