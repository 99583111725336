.ProfileComponent {
    .form-group {
        padding: 10px;
        background: #fff;
        border: 1px #eaeaea solid;
        border-radius: 5px;
        position: relative;

        &.highlight {
            border: 1px red solid;

            label {
                color: red;
            }

            .updateIcon {
                color: red;
            }

            .form-control {
                border-top: 1px #ffe0e0 solid;
                color: red;
            }
        }

        label {
            font-size: 12px;
            padding-left: 5px;
            color: #999;
            margin: 0;
        }

        .form-control {
            background: transparent;
            padding-left: 5px;
            border: none;
            border-radius: 0;
            border-top: 1px #f5f5f5 solid;
        }

        .updateIcon {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            color: #ccc;

            &:hover {
                color: #666;
            }
        }
    }

    .alertMessage {
        font-size: 14px;
        color: #000;
        padding: 1.5rem 2rem;
        background-color: #fef67f;
        border-color: #ffd29b;

        svg {
            margin: 0 7px;
            color: red;
        }

        &.light {
            background-color: #e7fda4;
            border-color: #cae478;
        }
    }
}