.EmployeeSearchForm {
    p{
        // margin-top: 8px;
        position: relative;
        top: 8px;
    }
    button{
        padding: 4px 12px;
        font-size: 18px;
    }
    .boxFieldForm {
        input {
            display: inline-block;
            width: 40px;
            margin: 0 5px;
            text-align: center;
            padding-left: 0;
            height: 40px;
            padding-right: 0;

            @media screen and (max-width: '700px') {
                width: 40px;
                border-radius: 0;
                margin: -1px;
            }
        }
    }
}