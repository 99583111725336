.AssessmentList{
    h4 {
        font-size: 1rem;
        color: #007bff;
    }

    .heading {
        margin-bottom: 5px;
        margin-top: 40px;
        // border-top: 1px #eaeaea solid;
        padding-top: 13px;

        button {
            position: relative;
            top: -3px;
            right: 0px;
            border-radius: 20px;
            padding: 3px 10px;
            font-size: 12px;
            // background: transparent;
            border: none;
            // color: #333;

            svg {
                margin-right: 8px;
            }
        }
    }

    .subList {
        // padding: 20px 0;
        border: 1px #eaeaea solid;
        padding: 0 15px;

        .row {
            padding: 12px 0;
            background: #fff;

            &:nth-child(even) {
                background: #fcfdff;
                border-top: 1px #ecf4fb solid;
                border-bottom: 1px #ecf4fb solid;
            }

            p{
                margin: 0;
            }

            button {
                padding: 2px 15px;
                // font-size: 12px;
            }
        }

    }
    .Scorecard{
        .subList .row
        {
            button{
                width: 30px;
                height: 30px;
                border-radius: 22px;
                padding: 0px;
            }
            
            display: flex;
            align-items: center;

            &:nth-child(even) {
                background-color: #fff;
                background: #fff;
            }
        }
      
        p{
            font-size: 0.8rem;
            color: #007bff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
        }
        .heading button {
            position: absolute;
            /* top: -3px; */
            right: 0px;
            border-radius: 20px;
            padding: 0px;
            font-size: 12px;
            width: 20px;
            height: 20px;
            /* text-align: center !important; */
        }
         .heading button svg {
            margin-right: 0px;
        }
        .collapIcon{
            
            position: absolute;
            right: 23px;

        }
        .subList {
            border:none;
            padding:0;
        }
        .subcom{
   
                font-weight: 600;
                .col-md-1{
                    text-align: center;
                }
            
        }

            .question{
                padding: 0px;
                // padding-left: 12px;
                font-size: 13px;
                .col-md-5{

                    background-color: #f9f9f9;
                    padding: 12px 15px;
                    
                }
                .col-md-1{
                    text-align: center;
                    // margin-left: -5px;
                }
            }
        
    }
}
.totalCard
{
    padding: 12px 0;
    background: #fff;
    border: 3px solid #f6fbff;
}
.yearCard{
    padding: 12px 0;
    margin: 20;
    background: #fff;
    border: 1px solid #cccccc;
    cursor: pointer;
}


.instructions {
    text-align: left;
}