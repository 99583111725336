.MainHeader{
    .bg-dark{
        background: black !important;
        text-transform: uppercase;

        .nav-item{
            padding: 0 15px;
            
            a{
                color: #fff;
                transition: all 0.2s ease-in-out;

                &:hover{
                    color: #f4d506;
                }
            }
        }
    }

    .bg-light{
        background: #007bff !important;
        text-transform: uppercase;
        // padding: 26px 1rem !important;

        &.tabsNavigation {
            background: #fff !important;
            padding-top: 30px;
            padding-bottom: 0;

            .nav-item{
                padding: 5px 32px;
    
                &.isActive {
                    background: #f6fbff;
    
                    a {
                        font-weight: 500;
                        &:hover{
                            color: #000;
                        }
                    }
                }
                
                a{
                    color: #000;
                    transition: all 0.2s ease-in-out;
    
                    &:hover{
                        color: #000;
                    }
                }
            }
        }

        .nav-item{
            padding: 0 15px;

            &.isActive {
                background: #034d9c;

                a {
                    &:hover{
                        color: #fff;
                    }
                }
            }
            
            a{
                color: #fff;
                transition: all 0.2s ease-in-out;

                &:hover{
                    color: #fff;
                }
            }
        }

        .navbar-brand {
            color: #fff;
        }
    }
    .dropdown-menu{
        right: 5px;
        background: #1067c3;
        border-radius: 0;
        top: calc(100% + 8px);
        margin: 0;
        // color: #fff;
        padding: 0;
        // min-width: 200px;

        .dropdown-item{
            color: #fff;
            outline: none;
            font-size: 14px;
            padding: 0;
            transition: 0.2s all ease-in-out;

            a{
                padding-left: 20px;
            }

            &:hover{
                background: transparent;
                color: #f4d506;
            }
        }

        .dropdown-divider{
            margin: 0;
            border-color: #333;
        }
    }

    .userIconHeader {
        padding: 9px;
        left: 10px;
        margin-bottom: -10px;
        bottom: 3px;
        position: relative;
        color: #007bff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #034d9c;
    }
}



@import './generic.scss'; // importing the generic css for whole project

@media screen and (max-width: '767px'){ //mobile fixes
    // @import './responsive.scss';
}