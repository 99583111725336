.DMEProfileBox {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4vh 0 7vh 0;
    z-index: 0;

    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background: rgba(11, 37, 72, 0.9);
        // background: #000000d9;
        z-index: -1;
    }

    .schoolBox{
        &.topSchoolBox{
            &.bottomBox {
                .oneInfo {
                    .iconBox {
                        border: 5px #3d1f42 solid;
                        background: transparent !important;
                    }

                    h3 {
                        color: #68366f !important;
                    }

                    svg {
                        path {
                            fill: #3d1f42 !important;
                        }
                    }
                }
            }
        }
    }

    // .col {
    //     // border-right: 1px #fff solid;
    //     padding-top: 0vh;
    //     padding-bottom: 0vh;
    //     position: relative;
        
    //     &:last-child {
    //         border-right: inherit;
    //     }

    //     h3 {
    //         font-size: 2rem;
    //         color: #fff;

    //         span {
    //             font-size: 4rem;
    //             text-decoration: underline;
    //         }
    //     }
    //     p {
    //         padding: 0 6vw;
    //         margin: 0;
    //         font-size: 1rem;
    //         color: #fff;
    //     }


    // }



}