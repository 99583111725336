.LoginComponent {
    .boxFieldForm {
        input {
            display: inline-block;
            width: 50px;
            margin: 0 5px;
            text-align: center;
            padding-left: 0;
            padding-right: 0;

            @media screen and (max-width: '700px') {
                width: 40px;
                border-radius: 0;
                margin: -1px;
            }
        }
    }
}